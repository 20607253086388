<template>
  <Layout :tituloPagina="`Hotspot | Perfiles de usuario | ${modo == 'nuevo' ? 'Nuevo' : 'Edicion'}`">
    <div class="row">
      <div class="col-lg-10">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nuevo' ? 'Nuevo' : 'Edicion'}} perfil
              <small class="font-size-10">
                Aquí puedes {{modo == 'nuevo' ? 'registrar' : 'editar'}} un perfil
              </small>
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Nombre del perfil</label>
                <input
                  class="form-control"
                  type="text"
                  name="nombre"
                  ref="nombre"
                  v-model="perfil.nombre"
                  placeholder="Ingrese un nombre"
                />
              </div>
            </div>

            <br>

            <div class="row">
              <div class="col-md-6">
                <label for="">Velocidad de subida</label>
                <sup class="text-info" v-show="perfil.velocidad_carga == 0">
                  0 indica velocidad ilimitada
                </sup>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="perfil.velocidad_carga"
                    name="velocidadCarga"
                    ref="velocidadCarga"
                    min="0"
                  />
                  <select
                    style="width: 0px;"
                    v-model="perfil.tipo_velocidad_carga"
                    class="form-select"
                    name="tipoVelocidadCarga"
                  >
                    <option value="k">Kbps</option>
                    <option value="M">Mbps</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Velocidad de bajada</label>
                <sup class="text-info" v-show="perfil.velocidad_descarga == 0">
                  0 indica velocidad ilimitada
                </sup>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="perfil.velocidad_descarga"
                    name="velocidadDescarga"
                    ref="velocidadDescarga"
                    min="0"
                  />
                  <select
                    v-model="perfil.tipo_velocidad_descarga"
                    class="form-select"
                    name="tipoVelocidadCarga"
                  >
                    <option value="k">Kbps</option>
                    <option value="M">Mbps</option>
                  </select>
                </div>
              </div>
            </div>
            
            <br>

            <div class="row">
              <div class="col-md-4" style="border-right: #E5E6E7 1px solid;">
                <label>Tiempo limite de ficha</label>
                <div class="row text-center">
                  <div class="col-sm-4 col-4">
                    <input
                      name="tiempoDias" type="number"
                      class="form-control" ref="tiempoDias"
                      v-model="perfil.tiempo_fichas_dias"
                      min="0" max="365"
                    />
                    <label>Dias</label>
                  </div>
                  <div class="col-sm-4 col-4">
                    <input
                      name="tiempoHoras" type="number"
                      class="form-control" ref="tiempoHoras"
                      v-model="perfil.tiempo_fichas_horas"
                      min="0" max="23"
                    />
                    <label>Horas</label>
                  </div>
                  <div class="col-sm-4 col-4">
                    <input
                      name="tiempoMinutos" type="number"
                      class="form-control" ref="tiempoMinutos"
                      v-model="perfil.tiempo_fichas_minutos"
                      min="0" max="59"
                    />
                    <label>Minutos</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4" style="border-right: #E5E6E7 1px solid;">
                <label>Ficha expira en</label>
                <div class="row text-center">
                  <div class="col-sm-4 col-4">
                    <input
                      name="expiracionDias" type="number"
                      class="form-control" ref="expiracionDias"
                      v-model="perfil.expiracion_dias"
                      min="0" max="365"
                    />
                    <label>Dias</label>
                  </div>
                  <div class="col-sm-4 col-4">
                    <input
                      name="expiracionHoras" type="number"
                      class="form-control" ref="expiracionHoras" 
                      v-model="perfil.expiracion_horas"
                      min="0" max="23"
                    />
                    <label>Horas</label>
                  </div>
                  <div class="col-sm-4 col-4">
                    <input
                      name="expiracionMinutos" type="number"
                      class="form-control" ref="expiracionMinutos"
                      v-model="perfil.expiracion_minutos"
                      min="0" max="59"
                    />
                    <label>Minutos</label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Limite de trafico</label>
                <div class="row">
                  <div class="col-sm-4 col-5">
                    <div class="form-check form-switch form-switch-lg">
                      <input
                        class="form-check-input" type="checkbox"
                        id="limite-trafico"
                        :checked="perfil.limite_trafico"
                        @click="
                          perfil.limite_trafico = !perfil.limite_trafico,
                          perfil.limite_trafico_total = 0,
                          perfil.tipo_limite_trafico_total = 'k'
                        "
                      />
                      <label class="form-check-label" for="limite-trafico"></label>
                    </div>
                  </div>
                  <div class="col-sm-8 col-7">
                    <div class="input-group">
                      <input
                        style="min-width: 34px;"
                        name="limiteTrafico"
                        ref="limiteTrafico"
                        type="number"
                        class="form-control"
                        v-model="perfil.limite_trafico_total"
                        :disabled="!perfil.limite_trafico"
                        min="0"
                        :max="maxLimiteTotal(perfil.tipo_limite_trafico_total)"
                      />
                      <select
                        :disabled="!perfil.limite_trafico"
                        v-model="perfil.tipo_limite_trafico_total"
                        class="form-select"
                        name="tipoVelocidadCarga"
                      >
                        <option value="k">KB</option>
                        <option value="M">MB</option>
                        <option value="G">GB</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-3">
                <label>Usuarios compartidos</label>
                <input
                  ref="usuarioCompartido"
                  style="min-width: 34px;"
                  name="limiteTrafico"
                  type="number"
                  class="form-control"
                  oninput="this.value = Math.abs(this.value)"
                  v-model="perfil.usuarios_compartidos"
                  min="0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10">
        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button
              class="btn btn-success"
              @click="modo == 'nuevo' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import { APIHOTS as API } from '@/API.js'
import PerfilSrv from '@/services/hotspot/PerfilSrv.js'
import Layout from "@/views/layouts/main"
export default {
  name: 'EdicionPerfil',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      perfil: {
        id: null,
        nombre: '',

        velocidad_carga: 0,
        tipo_velocidad_carga: 'k',

        velocidad_descarga: 0,
        tipo_velocidad_descarga: 'k',

        tiempo_fichas_dias: 0,
        tiempo_fichas_horas: 0,
        tiempo_fichas_minutos: 0,

        expiracion_dias: 0,
        expiracion_horas: 0,
        expiracion_minutos: 0,

        limite_trafico: false,
        limite_trafico_total: 0,
        tipo_limite_trafico_total: 'k',
        usuarios_compartidos: 1
      },
      perfil_temporal: {},
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this

    // Detección de modo
    if (this.$route.path.indexOf('nuevo') >= 0) this.modo = 'nuevo'
    else this.modo = 'edicion'

    if(self.modo == 'edicion') self.cargarPerfil()
  },

  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) return

      var perfil = {
        id: self.perfil.id,
        nombre: self.perfil.nombre,
        velocidad_carga: self.perfil.velocidad_carga+self.perfil.tipo_velocidad_carga,
        velocidad_descarga: self.perfil.velocidad_descarga+self.perfil.tipo_velocidad_descarga,
        tiempo_fichas: self.calcularDiasASegundos(self.perfil.tiempo_fichas_dias)+self.calcularHorasASegundos(self.perfil.tiempo_fichas_horas)+ self.calcularMinutosASegundos(self.perfil.tiempo_fichas_minutos),
        expiracion: self.calcularDiasASegundos(self.perfil.expiracion_dias)+self.calcularHorasASegundos(self.perfil.expiracion_horas)+self.calcularMinutosASegundos(self.perfil.expiracion_minutos),
        created_at: self.perfil.created_at,
        updated_at: self.perfil.updated_at,
        usuarios_compartidos: self.perfil.usuarios_compartidos
      }

      if(self.perfil.limite_trafico){
        Object.assign(perfil,{
          limite_trafico_total: this.calcularBits(self.perfil.limite_trafico_total,self.perfil.tipo_limite_trafico_total)
        })
      } else {
        Object.assign(perfil,{
          limite_trafico_total: null
        })
      }

      PerfilSrv.actualizar(perfil).then(response => {
        iu.msg.success("Se actualizo correctamente el perfil")
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'Hubo un problema al actualizar verifique los datos correctamente'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarPerfil: function() {
      var self = this,
          idPerfil = self.$route.params.id

      PerfilSrv.perfilJSON(idPerfil).then(response => {
        self.perfil_temporal = response.data
        self.construiPerfil()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'Se ocaciono un problema y no se puede cargar el perfil seleccionado'
        }
        iu.msg.error(mensaje)
        console.log(error)
        //self.cerrar()
      })
    },
    
    cerrar: function() {
      this.atras()
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return [ dias, horas, minutos ]
    },
    
    convertidorBit_a_kMG:function(bites){
      var gb = Math.floor((bites / 1073741824))

      if(gb >= 1) {
        return [gb,'G']
      }

      var mb = Math.floor((bites / 1048576 ) % 1024)
      if(mb >= 1) {
        return [mb,'M']
      }

      var kb = Math.floor((bites / 1024 ) % 1024)
      if(kb >= 1) {
        return [kb,'k']
      }
    },

    construiPerfil: function() {
      var self = this, nuevo_perfil = {}

      var tipoVelocidadCarga = self.perfil_temporal.velocidad_carga.charAt(self.perfil_temporal.velocidad_carga.length - 1);
      var velocidadCarga =self.perfil_temporal.velocidad_carga.substr(0,self.perfil_temporal.velocidad_carga.length-1)

      if(tipoVelocidadCarga != 'k' && tipoVelocidadCarga != 'M') {
        tipoVelocidadCarga = 'k'
      }

      var tipoVelocidadDescarga = self.perfil_temporal.velocidad_descarga.charAt(self.perfil_temporal.velocidad_descarga.length - 1);
      var velocidadDescarga =self.perfil_temporal.velocidad_descarga.substr(0,self.perfil_temporal.velocidad_descarga.length-1)

      if(tipoVelocidadDescarga != 'k' && tipoVelocidadDescarga != 'M') {
        tipoVelocidadDescarga = 'k'
      }
      
      Object.assign(nuevo_perfil, {
        id: self.perfil_temporal.id,
        nombre: self.perfil_temporal.nombre,
        created_at: self.perfil_temporal.created_at,
        updated_at: self.perfil_temporal.updated_at,

        velocidad_carga: velocidadCarga,
        tipo_velocidad_carga: tipoVelocidadCarga,

        velocidad_descarga: velocidadDescarga,
        tipo_velocidad_descarga: tipoVelocidadDescarga,

        tiempo_fichas_dias: self.convertidorSegundos_a_DHM(self.perfil_temporal.tiempo_fichas )[0],
        tiempo_fichas_horas: self.convertidorSegundos_a_DHM(self.perfil_temporal.tiempo_fichas )[1],
        tiempo_fichas_minutos: self.convertidorSegundos_a_DHM(self.perfil_temporal.tiempo_fichas )[2],

        expiracion_dias: self.convertidorSegundos_a_DHM(self.perfil_temporal.expiracion)[0],
        expiracion_horas: self.convertidorSegundos_a_DHM(self.perfil_temporal.expiracion)[1],
        expiracion_minutos: self.convertidorSegundos_a_DHM(self.perfil_temporal.expiracion)[2],

        limite_trafico: self.perfil_temporal.limite_trafico_total != null ? true: false,
        limite_trafico_total: self.perfil_temporal.limite_trafico_total != null ? self.convertidorBit_a_kMG(parseInt(self.perfil_temporal.limite_trafico_total))[0] : 0,
        tipo_limite_trafico_total: self.perfil_temporal.limite_trafico_total != null ? self.convertidorBit_a_kMG(self.perfil_temporal.limite_trafico_total)[1] : 'k',
        usuarios_compartidos: self.perfil_temporal.usuarios_compartidos
      })
      
      self.perfil = nuevo_perfil

      self.perfil_temporal = {}
    },

    guardar: function() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) return

      var perfil = {
        nombre: self.perfil.nombre,
        velocidad_carga: self.perfil.velocidad_carga+self.perfil.tipo_velocidad_carga,
        velocidad_descarga: self.perfil.velocidad_descarga+self.perfil.tipo_velocidad_descarga,
        tiempo_fichas: self.calcularDiasASegundos(self.perfil.tiempo_fichas_dias)+self.calcularHorasASegundos(self.perfil.tiempo_fichas_horas)+ self.calcularMinutosASegundos(self.perfil.tiempo_fichas_minutos),
        expiracion: self.calcularDiasASegundos(self.perfil.expiracion_dias)+self.calcularHorasASegundos(self.perfil.expiracion_horas)+self.calcularMinutosASegundos(self.perfil.expiracion_minutos),
        usuarios_compartidos: self.perfil.usuarios_compartidos
      }

      if(self.perfil.limite_trafico){
        Object.assign(perfil,{
          limite_trafico_total: this.calcularBits(self.perfil.limite_trafico_total,self.perfil.tipo_limite_trafico_total)
        })
      }
      // console.log(perfil)
      PerfilSrv.guardar(perfil).then(response => {
        iu.msg.success("Se guardo el nuevo perfil correctamente")
        var id = response.data

        self.perfil.id = id
        
        self.modo = 'edicion'
        
        self.$router.replace({
          name: 'edicionPerfilHotspot',
          params: {
            id: id
          }
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se puedo guardar el perfil'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },

    calcularBits: function(total, tipo){
      if(tipo == 'k') {
        return total * 1024
      }
      if(tipo == 'M') {
        return total * 1048576
      }
      if(tipo == 'G'){
        return total * 1073741824
      }
    },

    calcularDiasASegundos(dias){
      return dias * 86400
    },
    
    calcularHorasASegundos(horas){
      return horas * 3600

    },

    calcularMinutosASegundos(minutos){
      return minutos * 60
    },

    maxLimiteTotal:function(tipo){
      if(tipo == 'G') return '10240'
      if(tipo == 'M') return '10485760'
      if(tipo == 'k') return '10737418240'
    },

    verificarDatosCorrectos(){
      var self = this,
          perfil = self.perfil

      if(perfil.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.velocidad_carga < 0){
        iu.msg.warning('Se requiere que la velocidad de subida no sea menor a 0')
        self.$refs.velocidadCarga.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.velocidad_descarga < 0){
        iu.msg.warning('Se requiere que la velocidad de bajada no sea menor a 0')
        self.$refs.velocidadDescarga.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.tiempo_fichas_dias > 365 || perfil.tiempo_fichas_dias < 0){
        iu.msg.warning('El tiempo no puede se mas de 365 dias o menor a cero dias')
        self.$refs.tiempoDias.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.tiempo_fichas_horas >= 23 || perfil.tiempo_fichas_horas < 0){
        iu.msg.warning('El tiempo no puede se mas de 23 horas o menor a cero horas')
        self.$refs.tiempoHoras.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.tiempo_fichas_minutos >= 59 || perfil.tiempo_fichas_minutos < 0){
        iu.msg.warning('El tiempo no puede se mas de 59 minutos o menor a cero minutos')
        self.$refs.tiempoMinutos.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.expiracion_dias > 365 || perfil.expiracion_dias < 0){
        iu.msg.warning('El expiración no puede se mas de 365 dias o menor a cero dias')
        self.$refs.expiracionDias.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.expiracion_horas >= 23 || perfil.expiracion_horas < 0){
        iu.msg.warning('El expiración no puede se mas de 23 horas o menor a cero horas')
        self.$refs.expiracionHoras.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.expiracion_minutos >= 59 || perfil.expiracion_minutos < 0){
        iu.msg.warning('El expiración no puede se mas de 59 minutos o menor a cero minutos')
        self.$refs.expiracionMinutos.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.limite_trafico && perfil.tipo_limite_trafico_total== 'G' && (perfil.limite_trafico_total <= 0 )){
        iu.msg.warning('Se requiere que el limite de trafico para Gb no sea menos a 0 o mayor a 10240')
        self.$refs.limiteTrafico.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.limite_trafico && perfil.tipo_limite_trafico_total== 'M' && (perfil.limite_trafico_total <= 0 )){
        iu.msg.warning('Se requiere que el limite de trafico para Mb no sea menos a 0 o mayor a 10485760')
        self.$refs.limiteTrafico.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.limite_trafico && perfil.tipo_limite_trafico_total== 'k' && (perfil.limite_trafico_total <= 0 )){
        iu.msg.warning('Se requiere que el limite de trafico para kb no sea menos a 0 o mayor a 10737418240')
        self.$refs.limiteTrafico.focus()
        self.bandera_spinner = false
        return false
      }

      if(perfil.usuarios_compartidos == null || perfil.usuarios_compartidos == ""){
        iu.msg.warning('El valor de usuarios compartidos no puede ir vacío, ingrese un valor válido')
        self.$refs.usuarioCompartido.focus()
        self.bandera_spinner = false
        return false
      }

      return true
    }
  }
}
</script>